.login-box {
    background-color: #305629;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.btn-login,.btn-login:hover {
    background-color: #B75E47;
    color:#fff;
}
.btn-login:hover,.btn-login:active {
    background-color: #C68B67;
    color:#fff;
}

.btn-save,.btn-save:hover {
    background-color: #B75E47;
    color:#fff;
}
.btn-save:hover,.btn-save:active {
    background-color: #C68B67;
    color:#fff;
}


.header {
    background-color: #5A7F52;
    height:60px;
}
.header img.navbar-brand{
    height:50px;
}

img.avatar {
    border-radius:50%;
    width:50px;
    height:50px;
}

footer.bg-footer {
    background-color:#1C3E23;
    color:#fff;
}